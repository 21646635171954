import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { TOKEN_KEY, API_URL, JWT_KEY, TOKEN_EXPIRE_KEY, ACCOUNT_CODE_REF } from "../constant";
import { Card, Input, Button, Alert, Select, Badge } from "antd";
import axios from "axios";
import jwt from "jsonwebtoken";
import CenterLoading from "../components/CenterLoading";
import GetCurrencyOU from "../functions/GetCurrencyOU";
import ShowModalError from "../functions/ShowModalError";
import moment from "moment";
import { APP_VERSION } from "../constant";
import SetSaveResetTokenLogin from "../functions/SetSaveResetTokenLogin";
import GetMaintenance from "../functions/GetMaintenance";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

let token = null;
let accountCodeRef = {};

export default function Login() {
  const history = useHistory();

  const [redirectToReferer, setRedirectToReferer] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [listOu, setListOu] = useState([]);
  const [selectLoading, setSelectLoading] = useState(false);
  const [maintenanceStartTime, setMaintenanceStartTime] = useState(null);
  const [maintenanceEndTime, setMaintenanceEndTime] = useState(null);
  const [maintenanceShow, setMaintenanceShow] = useState(false);
  const [originalOU, setOriginalOU] = useState(null);

  useEffect(() => {
    fetchMaintenance();
  }, []);

  async function fetchMaintenance() {
    try {
      const res = await GetMaintenance();

      if (res.result && res.result.showMessage) {
        setMaintenanceStartTime(moment(res.result.start).format("DD MMMM YYYY, hh:mm A"));
        setMaintenanceEndTime(moment(res.result.end).format("DD MMMM YYYY, hh:mm A"));
        setMaintenanceShow(true);
      }
    } catch (e) {
      ShowModalError(e.message);
    }
  }

  function displayError(msg) {
    setShowError(true);
    setErrorMessage(msg);
    setLoading(false);
  }

  function displayDropdown(listOu) {
    setShowError(false);
    setErrorMessage("");
    setShowDropdown(true);
    setLoading(false);
    setListOu(listOu);
  }

  async function login() {
    setLoading(true);
    setShowError(false);

    try {
      let res = await axios({
        url: "/login",
        baseURL: API_URL,
        method: "post",
        data: {
          username,
          password,
        },
        withCredentials: true,
      });

      if (!res.data.success) throw Error(res.data.message);

      // set token
      token = res.data.token;
      accountCodeRef = res.data.accountCodeRef;

      if (res.data.showDropdown) {
        displayDropdown(res.data.listOu);
        setOriginalOU(res.data.user.ou);
      } else {
        select({ value: res.data.user.ou });
        // proceed();
      }
    } catch (e) {
      displayError(e.message);
    }
  }

  function proceed() {
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(TOKEN_EXPIRE_KEY, new Date().toISOString()); // refresh expire
    localStorage.setItem(ACCOUNT_CODE_REF, JSON.stringify(accountCodeRef || {}));
    setRedirectToReferer(true);
  }

  async function select(option) {
    // dapatkan ou semasa punya currencies
    // kemudian pulangkan token dari server
    // masuk ke localstorage
    const value = option.value;

    setSelectLoading(true);

    try {
      let res = await GetCurrencyOU({ ou: value });
      let currencies = res.result || [];

      // inject value "loginAs" dan "currencies" ke dalam token
      let user = jwt.verify(token, JWT_KEY);

      user.loginAs = value;
      user.loginAsCurrencies = currencies;

      token = jwt.sign(user, JWT_KEY);

      const resToken = await SetSaveResetTokenLogin({
        token: token,
      });

      token = resToken.token; // updated token

      proceed();
    } catch (e) {
      ShowModalError(e.message);
      setSelectLoading(false);
    }
  }

  function skipDropdown() {
    select({ value: originalOU });
  }

  const { from } = history.location.state || { from: { pathname: "/" } };
  const tokenExists = localStorage.getItem(TOKEN_KEY) ? true : false;

  // bila dah login, redirect semula
  if (redirectToReferer) {
    return <Redirect to={from} />;
  }

  // jika dah login (ada token)
  // tapi sengaja pergi ke '/login'
  // maka sistem akan redirect ke root '/'
  if (tokenExists) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  // jika tidak login, paparkan login box
  return (
    <div className="login">
      <div className={`first-bar ${injectStagingClassName()}`}>
        <img src="/tourism_malaysia_white.svg" className="logo" alt="Tourism Malaysia Logo" />
      </div>
      <div className={`second-bar ${injectStagingClassName()}`}>MyFIS Lite - Tourism Malaysia</div>

      {maintenanceShow ? (
        <Alert
          type="info"
          message={<b>Scheduled Maintenance</b>}
          description={
            <div>
              <p>
                Please note that we will be experiencing server downtime due to routine maintenance.
                <br />
                Time : {maintenanceStartTime} - {maintenanceEndTime}
                <br />
                <i>(UTC +8, Malaysia Time)</i>
              </p>
              <span>MyFIS Lite will not be available during this time.</span>
            </div>
          }
          showIcon
          style={{ margin: "15px auto 0 auto", marginBottom: 0, width: 600, borderWidth: 3 }}
        />
      ) : null}

      <Card title="Log in to MyFIS Lite" className="box">
        <Input
          addonAfter="@tourism.gov.my"
          prefix={<UserOutlined />}
          placeholder="Username"
          disabled={loading || showDropdown}
          onPressEnter={login}
          onChange={(e) => setUsername(e.target.value)}
          name="username"
          autoComplete="off"
          value={username}
          autoFocus
        />
        <Input
          prefix={<LockOutlined />}
          placeholder="Password"
          style={{ marginTop: 10 }}
          type="password"
          disabled={loading || showDropdown}
          onPressEnter={login}
          onChange={(e) => setPassword(e.target.value)}
          name="password"
          value={password}
        />

        {showDropdown ? (
          <>
            <div style={{ marginTop: 20 }}>
              Login as :<br />
              {selectLoading ? (
                <CenterLoading />
              ) : (
                <Select
                  autoFocus
                  showSearch
                  allowClear
                  style={{ width: "100%", marginTop: 10 }}
                  placeholder="Select Operating Unit"
                  onSelect={select}
                  labelInValue
                  filterOption={(input, option) => option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {listOu.map((ou) => (
                    <Select.Option value={ou.code} key={ou.code} title={ou.description}>
                      {ou.description} <Badge count={ou.unresolvedQuery} />
                    </Select.Option>
                  ))}
                </Select>
              )}
            </div>

            <div style={{ display: "flex", marginTop: 10, justifyContent: "space-between" }}>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  setShowDropdown(false);
                }}
              >
                Cancel
              </a>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  skipDropdown();
                }}
              >
                Skip (as {originalOU}) →
              </a>
            </div>
          </>
        ) : (
          <Button type="primary" onClick={login} style={{ width: "100%", marginTop: 20 }} loading={loading}>
            Login
          </Button>
        )}

        {showError ? (
          <Alert
            showIcon
            message={errorMessage}
            type="error"
            closable
            style={{ marginTop: 15 }}
            onClose={() => {
              setShowError(false);
              setErrorMessage("");
            }}
          />
        ) : null}
      </Card>

      <p className="footer-login">
        Version {APP_VERSION}
        <br />
        &#169; 2017-
        {moment().format("YYYY")} Tourism Malaysia
      </p>
    </div>
  );
}

function injectStagingClassName() {
  if (process.env.REACT_APP_IS_STAGING === "1") {
    return "staging";
  }
  return null;
}
