/*eslint-disable */
import React, { useEffect, useState } from "react";
import { Modal, Button, Tree, message } from "antd";
import CenterLoading from "../components/CenterLoading";
import ShowModalError from "../functions/ShowModalError";
import GetManageOfficeList from "../functions/GetManageOfficeList";
import SetSaveOfficeAccess from "../functions/SetSaveOfficeAccess";

const TreeNode = Tree.TreeNode;

export default function ModalManageOfficeAccess({ visible, staffId, staffName, onClose }) {
  const [isLoading, setIsLoading] = useState(true);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [loadingApply, setLoadingApply] = useState(false);
  const [listOffice, setListOffice] = useState([]);

  useEffect(() => {
    if (visible) {
      fetch(staffId);
    }
  }, [visible, staffId]);

  async function fetch(staffId) {
    setIsLoading(true);

    try {
      let res = await GetManageOfficeList({ staffId });
      let expandedKeys = [];

      res.data.offices.forEach((parent) => {
        expandedKeys.push(parent.key);
      });

      setListOffice(res.data.offices);
      setIsLoading(false);
      setCheckedKeys(res.data.allowedOfficeCodes);
      setExpandedKeys(expandedKeys);
      setListOffice(res.data.offices);
    } catch (e) {
      setIsLoading(false);
      ShowModalError(e.message);
    }

    /* const { staffId } = this.state;
    await this.setState({ isLoading: true });

    try {
      let res = await GetReceiptDetailsById({ id });

      this.setState({
        ...res,
        isLoading: false
      });
    } catch (e) {
      await this.setState({ isLoading: false });
      ShowModalError(e.message);
    } */
  }

  function reset() {
    setIsLoading(true);
    setExpandedKeys([]);
    setAutoExpandParent(true);
    setCheckedKeys([]);
    setLoadingApply(false);
    setListOffice([]);
  }

  async function apply() {
    try {
      setLoadingApply(true);

      await SetSaveOfficeAccess({
        staffId,
        listOu: checkedKeys,
      });

      setLoadingApply(false);

      message.success("Office access has been updated");

      onClose();
    } catch (e) {
      ShowModalError(e.message);
      setLoadingApply(false);
    }
  }

  function renderTreeNodes(data) {
    return data.map((item) => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode {...item} />;
    });
  }

  function onExpand(expandedKeys) {
    // console.log("onExpand", expandedKeys);
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  }

  function onCheck(checkedKeys) {
    setCheckedKeys(checkedKeys);
  }

  return (
    <Modal
      visible={visible}
      width={1000}
      footer={
        <>
          <Button disabled={isLoading || loadingApply} onClick={onClose}>
            Cancel
          </Button>
          <Button loading={loadingApply} disabled={isLoading} type="primary" onClick={apply}>
            Apply Changes
          </Button>
        </>
      }
      title="Manage Access - Office"
      onCancel={onClose}
      afterClose={reset}
    >
      {isLoading ? (
        <CenterLoading />
      ) : (
        <div>
          <table className="form">
            <tbody>
              <tr>
                <td className="label" style={{ width: 120 }}>
                  Staff Name
                </td>
                <td>{staffName}</td>
              </tr>
              <tr>
                <td className="label">Staff Id</td>
                <td>{staffId}</td>
              </tr>
              <tr>
                <td className="label">Offices</td>
                <td>
                  <Tree
                    selectable={false}
                    checkable
                    onExpand={onExpand}
                    expandedKeys={expandedKeys}
                    autoExpandParent={autoExpandParent}
                    onCheck={onCheck}
                    checkedKeys={checkedKeys}
                  >
                    {renderTreeNodes(listOffice)}
                  </Tree>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </Modal>
  );
}
