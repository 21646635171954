import axios from "axios";
import { API_URL } from "../constant";

const GetManageOfficeList = async ({ staffId }) => {
  return await axios({
    url: "/get-manage-office-list",
    baseURL: API_URL,
    method: "get",
    withCredentials: true,
    params: {
      staffId,
    },
  });
};

export default GetManageOfficeList;
