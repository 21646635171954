import React, { useState, useEffect } from "react";
import { Menu, Badge } from "antd";
import menuContent from "../menu-content";
import { useHistory } from "react-router-dom";
import GetUserGroupType from "../functions/GetUserGroupType";
// import MenuSearchContainer from "../containers/MenuSearchContainer";
import ShowModalError from "../functions/ShowModalError";
import {
  HomeOutlined,
  UserOutlined,
  DashboardOutlined,
  FileUnknownOutlined,
  AreaChartOutlined,
  WalletOutlined,
  FormOutlined,
  CalculatorOutlined,
  BankOutlined,
  ShoppingCartOutlined,
  DatabaseOutlined,
  LineChartOutlined,
  GlobalOutlined,
  SolutionOutlined,
  ToolOutlined,
  SettingOutlined,
} from "@ant-design/icons";

const { SubMenu } = Menu;
const rootKeys = rootSubmenuKeys(menuContent);

export default function SideMenu({ setBreadcrumb, setMenuOpenKeys, menuOpenKeys, userGroupType }) {
  const history = useHistory();
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [menuVisible, setMenuVisible] = useState([]);
  const [queryPendingTotal, setQueryPendingTotal] = useState(0);
  const [claimApprovalTotal, setClaimApprovalTotal] = useState(0);

  useEffect(() => {
    let newPath = history.location.pathname;
    setBreadcrumb(GenerateBreadcrumbString(menuContent, newPath));
    setSelectedKeys([newPath]);
  }, [history.location.pathname, setBreadcrumb]);

  useEffect(() => {
    let pathname = history.location.pathname;
    setSelectedKeys([pathname]);

    // ini untuk set breadcrumb direct
    // jika datang dari path direct (bukan dari '/')
    setBreadcrumb(GenerateBreadcrumbString(menuContent, pathname));
    setMenuOpenKeys(JSON.parse(localStorage.getItem("myfislite_menu_open")) || []);
  }, [history.location.pathname, setBreadcrumb, setMenuOpenKeys]);

  useEffect(() => {
    if (userGroupType) {
      setMenuVisible(userGroupType.result.paths || []);
      setQueryPendingTotal(userGroupType.queryPendingTotal);
      setClaimApprovalTotal(userGroupType.claimApprovalTotal);
    }
  }, [userGroupType]);

  function onOpenChange(openKeys) {
    const latestOpenKey = openKeys.find((key) => menuOpenKeys.indexOf(key) === -1);
    const isRootMenu = rootKeys.findIndex((key) => key === latestOpenKey) > -1 ? true : false;
    let finalKeys = [];

    if (isRootMenu) {
      finalKeys = [latestOpenKey];
    } else {
      finalKeys = openKeys;
    }

    setMenuOpenKeys(finalKeys);

    localStorage.setItem("myfislite_menu_open", JSON.stringify(finalKeys));
  }

  function handleClick(item) {
    let currentPath = history.location.pathname;

    if (currentPath !== item.key) {
      history.push(item.key);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  }

  return (
    <Menu
      openKeys={menuOpenKeys}
      onOpenChange={(keys) => onOpenChange(keys)}
      selectedKeys={selectedKeys}
      onClick={handleClick}
      mode="inline"
      className="sidemenu"
    >
      {GenerateMenuRecursive(menuContent, "", menuVisible, queryPendingTotal, claimApprovalTotal)}
    </Menu>
  );
}

function GenerateBreadcrumbString(menuContent, path) {
  let paths = path.split("/");
  let arr = [];
  let parentSource = menuContent;

  paths.shift();

  paths.forEach((p) => {
    let node = parentSource.find((i) => i.path === "/" + p);

    if (node) {
      let { title } = node;
      if (title) arr.push(title);
      parentSource = node.children;
    }
  });

  return arr;
}

// Generate menu untuk dimasukkan ke dalam element
function GenerateMenuRecursive(
  menuContent,
  parentPath = "",
  menuVisible = [],
  queryPendingTotal = 0,
  claimApprovalTotal = 0
) {
  let elem = [];

  menuContent.forEach((item) => {
    /**
     * path ini adalah gabungan dari parent, atuk, dan terus ke atas
     * selagi ada
     */
    let path = parentPath + item.path;

    if (menuVisible.indexOf(path) < 0) {
      // jika menu tiada dalam senarai
      // maka jangan paparkan
      return;
    }

    if (item.dividerTop) {
      elem.push(<Menu.Divider key={path + "_divider_top"} />);
    }

    if (item.children && item.children.length > 0) {
      elem.push(
        <SubMenu
          disabled={item.disabled}
          key={path}
          title={
            item.icon ? (
              <span>
                <MenuIcon name={item.icon} />
                <span>{item.title}</span>
                {path === "/query" && <Badge dot count={queryPendingTotal} style={{ marginLeft: 10 }} />}
                {path === "/personel-hq" && <Badge dot count={claimApprovalTotal} style={{ marginLeft: 10 }} />}
              </span>
            ) : (
              <span>{item.title}</span>
            )
          }
        >
          {GenerateMenuRecursive(item.children, path, menuVisible, queryPendingTotal, claimApprovalTotal)}
        </SubMenu>
      );
    } else {
      elem.push(
        <Menu.Item key={path} disabled={item.disabled}>
          <MenuIcon name={item.icon} />
          <span>{item.title}</span>
          {path === "/query/pending" && <Badge count={queryPendingTotal} style={{ marginLeft: 10 }} />}
          {path === "/personel-hq/approval" && <Badge count={claimApprovalTotal} style={{ marginLeft: 10 }} />}
        </Menu.Item>
      );
    }

    if (item.dividerBottom) {
      elem.push(<Menu.Divider key={path + "_divider_bottom"} />);
    }
  });

  return elem;
}

// dapatkan menu first level key
function rootSubmenuKeys(menuContent) {
  let res = [];

  menuContent.forEach((item) => {
    if (!item.divider && item.children) {
      res.push(item.path); //key is now path
    }
  });

  return res;
}

function MenuIcon({ name }) {
  const map = {
    home: <HomeOutlined />,
    user: <UserOutlined />,
    dashboard: <DashboardOutlined />,
    "file-unknown": <FileUnknownOutlined />,
    "area-chart": <AreaChartOutlined />,
    wallet: <WalletOutlined />,
    form: <FormOutlined />,
    calculator: <CalculatorOutlined />,
    bank: <BankOutlined />,
    "shopping-cart": <ShoppingCartOutlined />,
    database: <DatabaseOutlined />,
    "line-chart": <LineChartOutlined />,
    global: <GlobalOutlined />,
    solution: <SolutionOutlined />,
    tool: <ToolOutlined />,
    setting: <SettingOutlined />,
  };

  return map[name] || null;
}
