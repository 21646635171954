// prettier-ignore

const { REACT_APP_API_URL, REACT_APP_LITE_VERSION, REACT_APP_JWT_KEY } = process.env;

export const APP_VERSION = REACT_APP_LITE_VERSION || "-";
export const API_URL = REACT_APP_API_URL;
export const TOKEN_EXPIRE_MINUTE = 60;
export const TOKEN_KEY = "myfislite_token";
export const TOKEN_EXPIRE_KEY = "myfislite_token_expire_time";
export const JWT_KEY = REACT_APP_JWT_KEY;
export const OU_KEY = "myfislite_ou_view";
export const REPORT_KEY = "_lite_report_";
export const CREDITOR_LIST_KEY = "myfislite_creditors_raw";
export const ACCOUNT_LIST_KEY = "myfislite_accounts_list";
export const ACCOUNT_LIST_RECEIPT_KEY = "myfislite_accounts_list_receipt";
export const ACCOUNT_CODE_REF = "myfislite_acc_code_ref";
export const EIS_PARAM_KEY = "myfislite_eis_param";
export const AUTO_LOGOUT_IDLE_TIMEOUT = 20; // minutes

export const PERJALANAN_DALAM_NEGERI = "PERJALANAN_DALAM_NEGERI";
export const PERJALANAN_LUAR_NEGERI = "PERJALANAN_LUAR_NEGERI";
export const PERTUKARAN_DALAM_NEGERI = "PERTUKARAN_DALAM_NEGERI";
export const PERJALANAN_PERTUKARAN_LUAR_NEGERI = "PERJALANAN_PERTUKARAN_LUAR_NEGERI";

export const REF_CODE_15_TIPS_PORTRAGE = "013";
export const REF_CODE_3_PERCENT_FOREX_GAIN_LOSS = "015";
export const REF_CODE_MILEAGE = "014";
export const REF_CODE_MEAL_ALLOWANCE = "019";
export const REF_CODE_DAILY_ALLOWANCE = "018";
export const REF_CODE_HOTEL = "007";
export const REF_CODE_LODGING = "026";
