import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

const CenterLoading = () => (
  <div style={{ textAlign: "center", margin: 20 }}>
    <LoadingOutlined style={{ fontSize: 30 }} />
  </div>
);

export default CenterLoading;
