import axios from "axios";
import { API_URL } from "../constant";

const GetResolvedQueryForNotification = async () => {
  let res = await axios({
    url: "/get-resolved-query-for-notification",
    baseURL: API_URL,
    method: "get",
    withCredentials: true,
  });

  if (!res.data.success) {
    throw Error(res.data.message);
  }

  return res.data;
};

export default GetResolvedQueryForNotification;
