const GetMenuFoldStatus = () => {
  let value = localStorage.getItem("myfislite_menu_fold");

  if (value === "true") {
    return true;
  } else {
    return false;
  }
};

export default GetMenuFoldStatus;
