import React, { useState, useEffect } from "react";
import { Layout, Breadcrumb, Alert, Button } from "antd";
import { CalculatorOutlined } from "@ant-design/icons";
import SideMenu from "./SideMenu";
import HeaderContent from "./HeaderContent";
import { Switch, useHistory } from "react-router-dom";
import Navigation from "../Navigation";
import moment from "moment";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import GetUserGroupType from "../functions/GetUserGroupType";
import GetMenuFoldStatus from "../functions/GetMenuFoldStatus";
import ModalManageOfficeAccess from "../modals/ManageOfficeAccess";
import ModalResolvedQueryNotification from "../modals/ResolvedQueryNotification";
import ModalRateConverter from "../modals/RateConverter";
import { APP_VERSION } from "../constant";

const { Header, Content, Footer, Sider } = Layout;

export default function Wrapper({ loginName, loginAsDescription, setLoginName, setLoginAsDescription }) {
  const history = useHistory();
  const [pageLoading, setPageLoading] = useState(true);
  const [pageErrorMessage, setPageErrorMessage] = useState("");
  const [showPageError, setShowPageError] = useState(false);
  const [breadcrumb, setBreadcrumb] = useState(["Main"]);
  const [menuFold, setMenuFold] = useState(false);
  const [menuOpenKeys, setMenuOpenKeys] = useState([]);
  const [showModalRateConverter, setShowModalRateConverter] = useState(false);
  const [resultGetUserGroupType, setResultGetUserGroupType] = useState(null);

  function toggleMenuFold() {
    setMenuFold(!menuFold);
    setMenuOpenKeys(!menuFold ? [] : menuOpenKeys);

    // simpan dalam localstorage untuk persistent
    localStorage.setItem("myfislite_menu_fold", !menuFold);

    if (!menuFold) {
      localStorage.removeItem("myfislite_menu_open");
    }
  }

  useEffect(() => {
    fetchPaths(history.location.pathname);
    setMenuFold(GetMenuFoldStatus());
  }, [history.location.pathname]);

  async function fetchPaths(pathname) {
    setPageLoading(true);
    setShowPageError(false);

    try {
      let res = await GetUserGroupType();
      const { paths } = res.result;

      // jika dia tidak ada permission untuk view '/' main page
      // kita akan benarkan juga untuk lihat
      // sebab tak mau masuk2 terus dapat error message
      // tapi content main page kita akan limitkan
      if (pathname === "/") {
        // do something jika mahu?
      } else if (paths.indexOf(pathname) < 0) {
        throw Error("You do not have permission to view this page.");
      }

      setResultGetUserGroupType(res);
      setPageLoading(false);
    } catch (e) {
      setPageLoading(false);
      setShowPageError(true);
      setPageErrorMessage(e.message);
    }
  }

  function renderContent() {
    if (pageLoading) {
      return "Loading...";
    }

    if (!pageLoading && showPageError) {
      return <Alert message="Unauthorized" description={pageErrorMessage} type="error" showIcon />;
    }

    if (!pageLoading && !showPageError) {
      return (
        <Switch>
          <Navigation setLoginAsDescription={setLoginAsDescription} setLoginName={setLoginName} />
        </Switch>
      );
    }
  }

  // function toggleMenuFold() {
  //   AppContainer.toggleMenuFold();
  // }

  return (
    <Layout>
      <Header className={`top-header ${injectStagingClassName()}`}>
        <HeaderContent
          setLoginName={setLoginName}
          setLoginAsDescription={setLoginAsDescription}
          loginName={loginName}
          loginAsDescription={loginAsDescription}
        />
      </Header>
      <Content className="main-content">
        <div>
          <Button
            className="hide-print"
            style={{ float: "right" }}
            size="small"
            ghost
            type="primary"
            icon={<CalculatorOutlined />}
            onClick={() => setShowModalRateConverter(true)}
          >
            Rate Converter
          </Button>

          <Button
            className="menu-toggle"
            style={{ float: "left", margin: "-5px 0 0 20px" }}
            onClick={toggleMenuFold}
            icon={menuFold ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          ></Button>
          <Breadcrumb style={{ margin: "16px 0 16px 60px" }}>
            <Breadcrumb.Item>MyFIS Lite</Breadcrumb.Item>
            {breadcrumb.map((bc, index) => (
              <Breadcrumb.Item key={index}>{bc}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </div>

        <Layout className="content-wrapper">
          <Sider width={240} style={{ background: "#fff" }} collapsible collapsed={menuFold} trigger={null}>
            <SideMenu
              setBreadcrumb={setBreadcrumb}
              setMenuOpenKeys={setMenuOpenKeys}
              menuOpenKeys={menuOpenKeys}
              userGroupType={resultGetUserGroupType}
            />
          </Sider>
          <Content className="inner-content">{renderContent()}</Content>
        </Layout>

        <ModalManageOfficeAccess />
        <ModalResolvedQueryNotification />
        <ModalRateConverter visible={showModalRateConverter} onClose={() => setShowModalRateConverter(false)} />
      </Content>

      <Footer className="footer">
        MyFIS Lite v{APP_VERSION}
        <br />© 2017 - {moment().format("YYYY")} Tourism Malaysia
      </Footer>
    </Layout>
  );
}

function injectStagingClassName() {
  if (process.env.REACT_APP_IS_STAGING === "1") {
    return "staging";
  }
  return null;
}
