import axios from "axios";
import { API_URL } from "../constant";

// tidak perlu pass userId masa buat request
// server akan baca dari cookies
// jadi pastikan cookies betul

const GetUserGroupType = async () => {
  let res = await axios({
    url: "/get-user-group-type",
    baseURL: API_URL,
    method: "get",
    withCredentials: true,
  });

  return res.data;
};

export default GetUserGroupType;
