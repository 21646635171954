import React, { useState, useEffect } from "react";
import jwt from "jsonwebtoken";
import { message } from "antd";
import { TOKEN_KEY, JWT_KEY } from "../constant";
import GetCompanyDescription from "../functions/GetCompanyDescription";
import LogoutFromServer from "../functions/LogoutFromServer";
import ShowModalError from "../functions/ShowModalError";
import { UserOutlined, HomeOutlined, LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
const { PUBLIC_URL } = process.env;

export default function HeaderContent({ setLoginName, setLoginAsDescription, loginName, loginAsDescription }) {
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  async function logout() {
    message.loading("Logging out...", 0);

    try {
      await LogoutFromServer();

      message.destroy();

      localStorage.clear();

      history.replace("/login");
    } catch (e) {
      ShowModalError(e.message);
      message.destroy();
    }
  }

  async function storeInfo(setLoginName, setLoginAsDescription) {
    let token = localStorage.getItem(TOKEN_KEY);
    let user = jwt.verify(token, JWT_KEY);

    // jika dah ada loginAsDescription
    // maka loading tidak perlu
    if (user.loginAsDescription && user.loginAsDescription !== "") {
      setLoading(false);
    } else {
      try {
        let ouDescription = await GetCompanyDescription({ ou: user.loginAs, updateCookie: true });
        user.loginAsDescription = ouDescription;
        // sign token dan simpan balik dalam localstorage
        let newToken = jwt.sign(user, JWT_KEY);
        localStorage.setItem(TOKEN_KEY, newToken);
        setLoading(false);
      } catch (e) {
        ShowModalError(e.message);
        setLoading(false);
      }
    }

    setLoginName(user.name);
    setLoginAsDescription(user.loginAsDescription);
  }

  useEffect(() => {
    storeInfo(setLoginName, setLoginAsDescription);
  }, [setLoginName, setLoginAsDescription]);

  return (
    <div>
      <div className="logo title">
        <img src={`${PUBLIC_URL}/logo_app_myfislite.png`} alt="Logo MyFIS Lite" />
        <img src={`${PUBLIC_URL}/tourism_malaysia_white.svg`} className="tourism" alt="Tourism Malaysia Logo" />
      </div>
      <div className="right-side">
        {!loading ? (
          <>
            <span>
              <UserOutlined /> {loginName}
            </span>
            <span>
              <HomeOutlined /> {loginAsDescription}
            </span>
          </>
        ) : (
          <LoadingOutlined style={{ color: "#fff" }} />
        )}
        <span className="logout">
          <a
            onClick={(e) => {
              e.preventDefault();
              logout();
            }}
            href="#/"
          >
            Logout
          </a>
        </span>
      </div>
    </div>
  );
}
