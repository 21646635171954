import axios from "axios";
import { API_URL } from "../constant";

// month = '01'
// year = '2018'
// string, bukan number

const GetForexRate = async ({ month, year, currency }) => {
  let res = await axios({
    url: "/get-forex-rate",
    baseURL: API_URL,
    method: "get",
    withCredentials: true,
    params: { month, year, currency },
  });

  if (!res.data.success) {
    throw Error(res.data.message);
  }

  return res.data;
};

export default GetForexRate;
