import "react-app-polyfill/ie11";
import "ie-array-find-polyfill";
import "moment-timezone";
import React from "react";
import ReactDOM from "react-dom";
import { unregister } from "./registerServiceWorker";
import moment from "moment";
import findIndex from "array.prototype.findindex";
import includes from "array-includes";
import dotenv from "dotenv";
import App from "./App";
import "./index.css";

dotenv.config();
findIndex.shim();
includes.shim();

// set client timezone
// supaya follow malaysia
moment.tz.setDefault("Asia/Kuala_Lumpur");

ReactDOM.render(<App />, document.getElementById("root"));
//registerServiceWorker();
unregister();
