import React, { lazy } from "react";
import { Route } from "react-router-dom";

// pages
const EISSnapshot = lazy(() => import("./pages/EISSnapshot"));
const KATGrade = lazy(() => import("./pages/KATGrade"));
const AssetReport = lazy(() => import("./pages/AssetReport"));
const StoreReport = lazy(() => import("./pages/StoreReport"));
const EIS = lazy(() => import("./pages/EIS"));
const Main = lazy(() => import("./pages/Main"));
const Reports = lazy(() => import("./pages/Reports"));
const PaymentVoucherDataEditing = lazy(() => import("./pages/PaymentVoucher.DataEditing"));
const PaymentVoucherPosting = lazy(() => import("./pages/PaymentVoucher.Posting"));
const PaymentVoucherRestore = lazy(() => import("./pages/PaymentVoucher.Restore"));
const PaymentVoucherReport = lazy(() => import("./pages/PaymentVoucher.Report"));
const PaymentVoucherEnquiry = lazy(() => import("./pages/PaymentVoucher.Enquiry"));
const PettyCashVoucherDataEditing = lazy(() => import("./pages/PettyCashVoucher.DataEditing"));
const PettyCashVoucherPosting = lazy(() => import("./pages/PettyCashVoucher.Posting"));
const PettyCashVoucherRestore = lazy(() => import("./pages/PettyCashVoucher.Restore"));
const PettyCashVoucherReport = lazy(() => import("./pages/PettyCashVoucher.Report"));
const PettyCashVoucherEnquiry = lazy(() => import("./pages/PettyCashVoucher.Enquiry"));
const YearlyBudget = lazy(() => import("./pages/YearlyBudget"));
const GroupReferences = lazy(() => import("./pages/GroupReferences"));
const MenuPermission = lazy(() => import("./pages/MenuPermission"));
const GroupAssignment = lazy(() => import("./pages/GroupAssignment"));
const ChangeOU = lazy(() => import("./pages/ChangeOU"));
const AGsRate = lazy(() => import("./pages/AGsRate"));
const Creditors = lazy(() => import("./pages/Creditors"));
const Officers = lazy(() => import("./pages/Officers"));
const Emails = lazy(() => import("./pages/Emails"));
const ChartOfAccounts = lazy(() => import("./pages/ChartOfAccounts"));
const CostCentre = lazy(() => import("./pages/CostCentre"));
const PayslipCreation = lazy(() => import("./pages/PayslipCreation"));
const JournalDataEditing = lazy(() => import("./pages/Journal.DataEditing"));
const JournalPosting = lazy(() => import("./pages/Journal.Posting"));
const JournalRestore = lazy(() => import("./pages/Journal.Restore"));
const JournalReport = lazy(() => import("./pages/Journal.Report"));
const JournalEnquiry = lazy(() => import("./pages/Journal.Enquiry"));
const OpeningBalance = lazy(() => import("./pages/OpeningBalance"));
const OpeningBalancePettyCash = lazy(() => import("./pages/OpeningBalancePettyCash"));
const QuerySubmitQuery = lazy(() => import("./pages/Query.SubmitQuery"));
const QueryPosting = lazy(() => import("./pages/Query.Posting"));
const QueryFeedback = lazy(() => import("./pages/Query.Feedback"));
const QueryReport = lazy(() => import("./pages/Query.Report"));
const QueryStatistics = lazy(() => import("./pages/Query.Statistics"));
const InputtingBankStatement = lazy(() => import("./pages/InputtingBankStatement"));
const ClearedCheque = lazy(() => import("./pages/ClearedCheque"));
const UnpresentedCheque = lazy(() => import("./pages/UnpresentedCheque"));
const BalancePerBankStatement = lazy(() => import("./pages/BalancePerBankStatement"));
const ChequeDataEditing = lazy(() => import("./pages/Cheque.DataEditing"));
const ChequePosting = lazy(() => import("./pages/Cheque.Posting"));
const ChequeRestore = lazy(() => import("./pages/Cheque.Restore"));
const ChequeReport = lazy(() => import("./pages/Cheque.Report"));
const ChequeEnquiry = lazy(() => import("./pages/Cheque.Enquiry"));
const CashDataEditing = lazy(() => import("./pages/Cash.DataEditing"));
const CashDataReport = lazy(() => import("./pages/Cash.Report"));
const ReceiptDataEditing = lazy(() => import("./pages/Receipt.DataEditing"));
const ReceiptEnquiry = lazy(() => import("./pages/Receipt.Enquiry"));
const ReceiptPosting = lazy(() => import("./pages/Receipt.Posting"));
const ReceiptRestore = lazy(() => import("./pages/Receipt.Restore"));
const ReceiptReport = lazy(() => import("./pages/Receipt.Report"));
const Payslip = lazy(() => import("./pages/Payslip"));
const OfficeQueryFeedback = lazy(() => import("./pages/OfficeQuery.Feedback"));
const OfficeQueryPending = lazy(() => import("./pages/OfficeQuery.Pending"));
const OfficeQueryPendingReadOnly = lazy(() => import("./pages/OfficeQuery.PendingReadOnly"));
const EPS = lazy(() => import("./pages/EPS"));
const COAManager = lazy(() => import("./pages/COAManager"));
const GroupSetup = lazy(() => import("./pages/GroupSetup"));
const Advance = lazy(() => import("./pages/Advance"));
const AdvanceHQ = lazy(() => import("./pages/AdvanceHQ"));
const Log = lazy(() => import("./pages/Log"));
const CompareWithCore = lazy(() => import("./pages/CompareWithCore"));
const SpecialAllocationReportSettings = lazy(() => import("./pages/SpecialAllocationReportSettings"));
const OverallReports = lazy(() => import("./pages/OverallReports"));
const AdvanceApplicationPermission = lazy(() => import("./pages/AdvanceApplicationPermission"));
const Checklist = lazy(() => import("./pages/Checklist"));
const SuratAmaranPendahuluan = lazy(() => import("./pages/SuratAmaranPendahuluan"));
const IntegrationStatus = lazy(() => import("./pages/IntegrationStatus"));
const DepositDocumentImport = lazy(() => import("./pages/DepositDocumentImport"));
const Claim = lazy(() => import("./pages/Claim"));
const ClaimHQ = lazy(() => import("./pages/ClaimHQ"));
const OvertimeSetup = lazy(() => import("./pages/OvertimeSetup.js"));
const Overtime = lazy(() => import("./pages/Overtime"));
const ReceiptCodeConfiguration = lazy(() => import("./pages/ReceiptCodeConfiguration"));
const BillRegistration = lazy(() => import("./pages/BillRegistration"));
const PostingToCore = lazy(() => import("./pages/PostingToCore"));
const IntegrationScanner = lazy(() => import("./pages/IntegrationScanner"));
const AssetRegistration = lazy(() => import("./pages/AssetRegistration"));
const AssetListing = lazy(() => import("./pages/AssetListing"));
const Maintenance = lazy(() => import("./pages/Maintenance"));
const CountryList = lazy(() => import("./pages/CountryList"));
const StoreItems = lazy(() => import("./pages/Store.Items"));
const StoreItemRequest = lazy(() => import("./pages/Store.ItemRequest"));
const StoreApproval = lazy(() => import("./pages/Store.Approval"));
const StoreItemCategory = lazy(() => import("./pages/Store.ItemCategory"));
const JournalReportSetting = lazy(() => import("./pages/Journal.ReportSetting"));
const UnlockDocumentRestoration = lazy(() => import("./pages/UnlockDocumentRestoration"));
const ChecklistItems = lazy(() => import("./pages/ChecklistItems"));
const ClaimItems = lazy(() => import("./pages/ClaimItems"));
const AdvanceClaimHQApproval = lazy(() => import("./pages/AdvanceClaimHQApproval"));
const SejarahTuntutan = lazy(() => import("./pages/SejarahTuntutan"));

// prettier-ignore
export default function Navigation() {
  return (
    <React.Suspense fallback="Loading...">
      <Route exact path="/personel/payslip" component={Payslip} />
      <Route exact path="/personel/advance" component={Advance} />
      <Route exact path="/personel/claim" component={Claim} />
      <Route exact path="/personel/overtime" component={Overtime} />
      <Route exact path="/personel/store-item-request" component={StoreItemRequest} />

      <Route exact path="/personel-hq/advance" component={AdvanceHQ} />
      <Route exact path="/personel-hq/claim" component={ClaimHQ} />
      <Route exact path="/personel-hq/approval" component={AdvanceClaimHQApproval} />
      <Route exact path="/personel-hq/sejarah-tuntutan" component={SejarahTuntutan} />
      <Route exact path="/personel-hq/surat-peringatan-pendahuluan" component={SuratAmaranPendahuluan} />

      <Route exact path="/eis" component={EIS} />

      <Route exact path="/query/pending" component={OfficeQueryPending} />
      <Route exact path="/query/pending-read" component={OfficeQueryPendingReadOnly} />
      <Route exact path="/query/feedback" component={OfficeQueryFeedback} />

      <Route exact path="/account-payable/eps" component={EPS} />

      <Route exact path="/account-payable/bill-registration" component={BillRegistration} />

      <Route exact path="/account-payable/payment-voucher/data-editing" component={PaymentVoucherDataEditing} />
      <Route exact path="/account-payable/payment-voucher/posting" component={PaymentVoucherPosting} />
      <Route exact path="/account-payable/payment-voucher/restore" component={PaymentVoucherRestore} />
      <Route exact path="/account-payable/payment-voucher/report" component={PaymentVoucherReport} />
      <Route exact path="/account-payable/payment-voucher/enquiry" component={PaymentVoucherEnquiry} />

      <Route exact path="/account-payable/cheque/data-editing" component={ChequeDataEditing} />
      <Route exact path="/account-payable/cheque/posting" component={ChequePosting} />
      <Route exact path="/account-payable/cheque/restore" component={ChequeRestore} />
      <Route exact path="/account-payable/cheque/report" component={ChequeReport} />
      <Route exact path="/account-payable/cheque/enquiry" component={ChequeEnquiry} />

      <Route exact path="/account-receivable/receipting/data-editing" component={ReceiptDataEditing} />
      <Route exact path="/account-receivable/receipting/posting" component={ReceiptPosting} />
      <Route exact path="/account-receivable/receipting/restore" component={ReceiptRestore} />
      <Route exact path="/account-receivable/receipting/report" component={ReceiptReport} />
      <Route exact path="/account-receivable/receipting/enquiry" component={ReceiptEnquiry} />

      <Route exact path="/petty-cash/petty-cash-voucher/data-editing" component={PettyCashVoucherDataEditing} />
      <Route exact path="/petty-cash/petty-cash-voucher/posting" component={PettyCashVoucherPosting} />
      <Route exact path="/petty-cash/petty-cash-voucher/restore" component={PettyCashVoucherRestore} />
      <Route exact path="/petty-cash/petty-cash-voucher/report" component={PettyCashVoucherReport} />
      <Route exact path="/petty-cash/petty-cash-voucher/enquiry" component={PettyCashVoucherEnquiry} />

      <Route exact path="/petty-cash/cash/data-editing" component={CashDataEditing} />
      <Route exact path="/petty-cash/cash/report" component={CashDataReport} />

      <Route exact path="/system-admin/user-access/menu-permission" component={MenuPermission} />
      <Route exact path="/system-admin/user-access/group-references" component={GroupReferences} />
      <Route exact path="/system-admin/user-access/group-assignment" component={GroupAssignment} />
      <Route exact path="/system-admin/user-access/group-setup" component={GroupSetup} />
      <Route exact path="/system-admin/log" component={Log} />
      <Route exact path="/system-admin/compare-with-core" component={CompareWithCore} /> 
      <Route exact path="/system-admin/maintenance" component={Maintenance} />
      <Route exact path="/system-admin/country-list" component={CountryList} />

      <Route exact path="/finance/change-ou" component={ChangeOU} />

      {/* <Route exact path="/finance/spmk-migration" component={SPMKMigration} /> */}
      <Route exact path="/finance/query/submit-query" component={QuerySubmitQuery} />
      <Route exact path="/finance/query/posting" component={QueryPosting} />
      <Route exact path="/finance/query/feedback" component={QueryFeedback} />
      <Route exact path="/finance/query/report" component={QueryReport} />
      <Route exact path="/finance/query/statistics" component={QueryStatistics} />
      <Route exact path="/finance/journal/data-editing" component={JournalDataEditing} />
      <Route exact path="/finance/journal/posting" component={JournalPosting} />
      <Route exact path="/finance/journal/restore" component={JournalRestore} />
      <Route exact path="/finance/journal/report" component={JournalReport} />
      <Route exact path="/finance/journal/enquiry" component={JournalEnquiry} />
      <Route exact path="/finance/journal/report-setting" component={JournalReportSetting} />
      <Route exact path="/finance/opening-balance" component={OpeningBalance} />
      <Route exact path="/finance/opening-balance-petty-cash" component={OpeningBalancePettyCash} />
      <Route exact path="/finance/advance-application-permission" component={AdvanceApplicationPermission} />
      <Route exact path="/finance/posting-to-core" component={PostingToCore} />
      <Route exact path="/finance/kat-grade" component={KATGrade} />
      <Route exact path="/finance/unlock-document-restore" component={UnlockDocumentRestoration} />
      <Route exact path="/finance/checklist-items" component={ChecklistItems} />
      <Route exact path="/finance/claim-items" component={ClaimItems} />
      
      <Route exact path="/tools/integration-status" component={IntegrationStatus} />
      <Route exact path="/tools/coa-manager" component={COAManager} />
      <Route exact path="/tools/special-allocation-report-settings" component={SpecialAllocationReportSettings} />
      <Route exact path="/tools/receipt-code-configuration" component={ReceiptCodeConfiguration} />
      <Route exact path="/tools/integration-scanner" component={IntegrationScanner} />
      <Route exact path="/tools/eis-snapshot" component={EISSnapshot} />
      <Route exact path="/tools/deposit-document-import" component={DepositDocumentImport} />

      <Route exact path="/asset/registration" component={AssetRegistration} />
      <Route exact path="/asset/listing" component={AssetListing} />
      <Route exact path="/asset/report" component={AssetReport} />

      <Route exact path="/store/items" component={StoreItems} />
      <Route exact path="/store/approval" component={StoreApproval} />
      <Route exact path="/store/report" component={StoreReport} />

      <Route exact path="/bank-recon/bank-reconciliation/inputting-bank-statement" component={InputtingBankStatement} />
      <Route exact path="/bank-recon/bank-reconciliation/cleared-cheque" component={ClearedCheque} />
      <Route exact path="/bank-recon/bank-reconciliation/unpresented-cheque" component={UnpresentedCheque} />
      <Route exact path="/bank-recon/balance-per-bank-statement" component={BalancePerBankStatement} />

      <Route exact path="/coding/ags-rate" component={AGsRate} />
      <Route exact path="/coding/creditors" component={Creditors} />
      <Route exact path="/coding/officers" component={Officers} />
      <Route exact path="/coding/emails" component={Emails} />
      <Route exact path="/coding/cost-centre" component={CostCentre} />
      <Route exact path="/coding/chart-of-accounts" component={ChartOfAccounts} />
      <Route exact path="/coding/payslip-creation" component={PayslipCreation} />
      <Route exact path="/coding/checklist" component={Checklist} />
      <Route exact path="/coding/overtime-setup" component={OvertimeSetup} />
      <Route exact path="/coding/store-item-category" component={StoreItemCategory} />

      <Route exact path="/reports" component={Reports} />
      <Route exact path="/overall-reports" component={OverallReports} />

      <Route exact path="/yearly-budget" component={YearlyBudget} />

      <Route exact path="/" component={Main} />
    </React.Suspense>
  )
}
