import React, { useState } from "react";
import { Modal, Radio, Input, Select, Button, DatePicker, Alert } from "antd";
import moment from "moment";
import GetForexRate from "../functions/GetForexRate";
import accounting from "accounting";
import replaceall from "replaceall";

const currencyList = [
  {
    code: "AED",
    description: "United Arab Emirates Dirham",
  },
  {
    code: "AUD",
    description: "Australian Dollar",
  },
  {
    code: "BDT",
    description: "Bangladesh Taka",
  },
  {
    code: "BND",
    description: "Brunei Dollar",
  },
  {
    code: "CAD",
    description: "Canadian Dollar",
  },
  {
    code: "CNY",
    description: "China Renminbi",
  },
  {
    code: "EUR",
    description: "Euro",
  },
  {
    code: "GBP",
    description: "British Pound Sterling",
  },
  {
    code: "HKD",
    description: "Hong Kong Dollar",
  },
  {
    code: "IDR",
    description: "Indonesian Rupiah",
  },
  {
    code: "INR",
    description: "Indian Rupee",
  },
  {
    code: "JPY",
    description: "Japanese Yen",
  },
  {
    code: "KRW",
    description: "South Korean Won",
  },
  {
    code: "KZT",
    description: "Kazakhstan Tenge",
  },
  {
    code: "NZD",
    description: "New Zealand Dollar",
  },
  {
    code: "PHP",
    description: "Philippines Peso",
  },
  {
    code: "RUB",
    description: "Russian Rubel",
  },
  {
    code: "SAR",
    description: "Saudi Riyal",
  },
  {
    code: "SEK",
    description: "Swedish Krona",
  },
  {
    code: "SGD",
    description: "Singapore Dollar",
  },
  {
    code: "THB",
    description: "Thai Baht",
  },
  {
    code: "TRY",
    description: "New Turkish Lira",
  },
  {
    code: "TWD",
    description: "Taiwan Dollar",
  },
  {
    code: "USD",
    description: "US Dollar",
  },
  {
    code: "ZAR",
    description: "South African Rand",
  },
];

export default function ModalRateConverter({ visible, onClose }) {
  const [convertType, setConvertType] = useState("toRinggit");
  const [foreignCurrency, setForeignCurrency] = useState(currencyList[0].code);
  const [foreignCurrencyDesc, setForeignCurrencyDesc] = useState(currencyList[0].description);
  const [amount, setAmount] = useState("");
  const [monthYear, setMonthYear] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [converting, setConverting] = useState(false);
  const [finalResult, setFinalResult] = useState("");

  async function convert() {
    let properAmount = replaceall(",", "", amount);
    let value = Number(properAmount);

    try {
      if (isNaN(value)) {
        throw Error("Please insert a valid amount");
      }

      setConverting(true);
      setShowError(false);

      const res = await GetForexRate({
        month: moment(monthYear, "MMYYYY").format("MM"),
        year: moment(monthYear, "MMYYYY").format("YYYY"),
        currency: foreignCurrency,
      });

      if (!res.success) {
        throw Error(res.message);
      }

      let { unit, rate } = res.result;

      unit = Number(unit);
      rate = Number(rate);

      let finalResult;

      if (convertType === "toRinggit") {
        finalResult = ConvertToRinggit(value, rate, unit);
      } else if (convertType === "toForeign") {
        finalResult = ConvertToForeign(value, rate, unit);
      }

      setConverting(false);
      setFinalResult(finalResult);
    } catch (e) {
      setShowError(true);
      setErrorMessage(e.message);
      setConverting(false);
    }
  }

  function resetState() {
    setConvertType("toRinggit");
    setForeignCurrency(currencyList[0].code);
    setForeignCurrencyDesc(currencyList[0].description);
    setAmount("");
    setMonthYear("");
    setShowError(false);
    setErrorMessage("");
    setConverting(false);
    setFinalResult("");
  }

  return (
    <Modal
      visible={visible}
      width={600}
      footer={
        <div>
          <Button onClick={resetState}>Reset</Button>
          <Button onClick={onClose}>Close</Button>
        </div>
      }
      title="Rate Converter"
      onCancel={onClose}
      // afterClose={resetState}
      // afterClose={() => this.setState({ ...defaultState })}
    >
      <table className="form">
        <tbody>
          <tr>
            <td className="label">Convert</td>
            <td>
              <Radio.Group
                value={convertType}
                onChange={(e) => {
                  setConvertType(e.target.value);
                  setFinalResult("");
                }}
                disabled={converting}
              >
                <Radio value="toRinggit">Foreign To MYR</Radio>
                <Radio value="toForeign">MYR to Foreign</Radio>
              </Radio.Group>
            </td>
          </tr>
          <tr>
            <td className="label" style={{ width: 180 }}>
              Foreign Currency
            </td>
            <td>
              <Select
                style={{ width: 100 }}
                value={foreignCurrency}
                onChange={(value) => {
                  const cur = currencyList.find((c) => c.code === value);

                  setForeignCurrency(value);
                  setForeignCurrencyDesc(cur.description);
                }}
                disabled={converting}
              >
                {currencyList.map((cur) => (
                  <Select.Option value={cur.code} key={cur.code}>
                    {cur.code}
                  </Select.Option>
                ))}
              </Select>
              <span style={{ marginLeft: 10 }}>{foreignCurrencyDesc}</span>
            </td>
          </tr>
          <tr>
            <td className="label">Month / Year</td>
            <td>
              <DatePicker.MonthPicker
                disabled={converting}
                style={{ width: 200 }}
                placeholder="Select month"
                format="MM/YYYY"
                allowClear={false}
                value={Boolean(monthYear) ? moment(monthYear, "MMYYYY") : null}
                onChange={(dm) => setMonthYear(dm.format("MMYYYY"))}
              />
            </td>
          </tr>
          <tr>
            <td className="label">Amount</td>
            <td>
              <Input
                value={amount}
                addonBefore={<code>{convertType === "toRinggit" ? foreignCurrency : "MYR"}</code>}
                style={{ width: 200 }}
                placeholder="Insert amount here"
                onChange={(e) => setAmount(e.target.value)}
                onBlur={(e) => {
                  let value = replaceall(",", "", e.target.value);
                  setAmount(value);
                }}
                disabled={converting}
                onPressEnter={convert}
              />
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                disabled={!Boolean(monthYear) || amount === ""}
                onClick={convert}
                loading={converting}
              >
                {converting ? "Converting..." : "Convert"}
              </Button>
            </td>
          </tr>
          <tr>
            <td className="label">
              <b>RESULT</b>
            </td>
            <td>
              <Input
                value={Boolean(finalResult) ? accounting.format(finalResult, 2) : null}
                addonBefore={<code>{convertType === "toRinggit" ? "MYR" : foreignCurrency}</code>}
                style={{ width: 200 }}
                readOnly
              />
            </td>
          </tr>
        </tbody>
      </table>

      {showError ? (
        <Alert
          message={errorMessage}
          type="error"
          showIcon
          style={{ marginTop: 10 }}
          closable
          onClose={() => {
            setShowError(false);
            setErrorMessage("");
          }}
        />
      ) : null}
    </Modal>
  );
}

function ConvertToForeign(figure, rate, unit) {
  return accounting.unformat(accounting.toFixed((figure / rate) * unit, 2));
}

function ConvertToRinggit(figure, rate, unit) {
  return accounting.unformat(accounting.toFixed(figure * (rate / unit), 2));
}
