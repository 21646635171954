import axios from "axios";
import { API_URL } from "../constant";

export default async function GetMaintenance() {
  let res = await axios({
    url: "/get-maintenance",
    baseURL: API_URL,
    method: "get",
    //withCredentials: true
  });

  if (!res.data.success) {
    throw Error(res.data.message);
  }

  return res.data;
}
