import React from "react";
import { Modal, message, Input } from "antd";
import LogoutFromServer from "./LogoutFromServer";

export default function ShowModalError(msg = "", preformat = false) {
  let modalContent = {
    title: "Error",
    content: msg,
    okText: "Close",
  };

  if (msg === "Unauthorized") {
    modalContent = {
      title: "Access Denied",
      content: <p>Please re-login to continue.</p>,
      onOk: async () => {
        message.loading("Loading...", 0);
        await LogoutFromServer();
        localStorage.clear();
        window.location.href = "/login";
      },
    };
  }

  if (preformat) {
    let lines = msg.split("\n");
    let properLines = [];

    for (let line of lines) {
      properLines.push(line.trim());
    }

    let message = properLines.join("\n");

    modalContent.content = (
      <Input.TextArea
        autoSize={{ minRows: 15, maxRows: 15 }}
        readOnly
        value={message}
        style={{ fontFamily: "Monaco, 'Lucida Sans Typewriter', Consolas, 'Courier New', Courier, monospace" }}
      />
    );
  }

  Modal.error(modalContent);
}
