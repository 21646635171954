/*eslint-disable */
import React, { useState, useEffect } from "react";
import { Modal, message } from "antd";
import CenterLoading from "../components/CenterLoading";
import { Button } from "antd";
import { SoundOutlined } from "@ant-design/icons";
import ShowModalError from "../functions/ShowModalError";
import GetResolvedQueryForNotification from "../functions/GetResolvedQueryForNotification";
import SetSaveSendEmailResolvedQuery from "../functions/SetSaveSendEmailResolvedQuery";

export default function ModalResolvedQueryNotification({ visible, onCancel }) {
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);
  const [loadingSend, setLoadingSend] = useState(false);

  function reset() {
    setIsLoading(true);
    setList([]);
    setLoadingSend(false);
  }

  async function fetch() {
    setIsLoading(true);

    try {
      let res = await GetResolvedQueryForNotification();

      setList(res.result);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      ShowModalError(e.message);
    }
  }

  async function sendEmail() {
    try {
      setLoadingSend(true);

      await SetSaveSendEmailResolvedQuery();

      onCancel();

      message.success("Email has been successfully sent");

      // reset();
    } catch (e) {
      setLoadingSend(false);
      ShowModalError(e.message);
    }
  }

  useEffect(() => {
    if (visible) {
      fetch();
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      width={700}
      footer={
        <>
          {list.length > 0 ? (
            <div>
              <Button disabled={loadingSend} onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={loadingSend} type="primary" icon={<SoundOutlined />} onClick={sendEmail}>
                Send Email
              </Button>
            </div>
          ) : (
            <Button onClick={onCancel}>Close</Button>
          )}
        </>
      }
      title="Resolved Notification"
      onCancel={onCancel}
      afterClose={reset}
    >
      {isLoading ? (
        <CenterLoading />
      ) : list.length > 0 ? (
        <div>
          <p>Send notification email for these resolved queries :</p>
          <div style={{ height: 200, overflowY: "scroll", border: "1px solid lightgrey" }}>
            <pre>
              {list.map((v, index) => (
                <span key={index}>
                  {index + 1} - {v.voucherNumber}
                  <br />
                </span>
              ))}
            </pre>
          </div>
        </div>
      ) : (
        <p>No resolved query at this moment.</p>
      )}
    </Modal>
  );
}
