import axios from "axios";
import { API_URL } from "../constant";

const GetCompanyDescription = async ({ ou, updateCookie }) => {
  let res = await axios({
    url: "/get-company-description",
    baseURL: API_URL,
    method: "get",
    params: {
      ou,
      updateCookie,
    },
    withCredentials: true,
  });

  if (res.data.result.length > 0) {
    return res.data.result[0].description;
  }

  return "";
};

export default GetCompanyDescription;
