const menuContent = [
  {
    title: "Main",
    path: "/",
    icon: "home",
  },
  {
    title: "Personel",
    path: "/personel",
    icon: "user",
    children: [
      {
        title: "Advance",
        path: "/advance",
        disabled: false,
      },
      {
        title: "Claim",
        path: "/claim",
        disabled: false,
      },
      {
        title: "Overtime",
        path: "/overtime",
        disabled: false,
      },
      {
        title: "Payslip",
        path: "/payslip",
      },
      {
        title: "Store Item Request",
        path: "/store-item-request",
      },
    ],
  },
  {
    title: "Tuntutan & Pendahuluan",
    path: "/personel-hq",
    icon: "user",
    children: [
      {
        title: "Pendahuluan",
        path: "/advance",
        disabled: false,
      },
      {
        title: "Tuntutan",
        path: "/claim",
        disabled: false,
      },
      {
        title: "Kelulusan",
        path: "/approval",
        disabled: false,
      },
      { title: "Surat Peringatan", path: "/surat-peringatan-pendahuluan" },
      { title: "Sejarah Tuntutan", path: "/sejarah-tuntutan" },
    ],
  },
  {
    title: "EIS",
    path: "/eis",
    dividerTop: true,
    icon: "dashboard",
  },
  {
    title: "Query",
    path: "/query",
    dividerTop: true,
    icon: "file-unknown",
    children: [
      { title: "Pending", path: "/pending" },
      { title: "Pending (Read-only)", path: "/pending-read" },
      { title: "Feedback", path: "/feedback" },
    ],
  },
  {
    title: "Yearly Budget",
    path: "/yearly-budget",
    icon: "area-chart",
  },
  /* {
    title: "Budget Report",
    path: "/budget-report",
    icon: "area-chart"
  }, */
  {
    title: "Account Payable",
    path: "/account-payable",
    icon: "wallet",
    children: [
      {
        title: "Bill Registration",
        path: "/bill-registration",
      },
      /* {
        title: "Bill Registration",
        path: "/bill-registration",
        children: [
          {
            title: "Create",
            path: "/create"
          },
          {
            title: "Enquiry",
            path: "/enquiry"
          }
        ]
      }, */
      {
        title: "Payment Voucher",
        path: "/payment-voucher",
        children: [
          {
            title: "Data Editing",
            path: "/data-editing",
          },
          {
            title: "Posting",
            path: "/posting",
          },
          {
            title: "Restore",
            path: "/restore",
          },
          {
            title: "Report",
            path: "/report",
          },
          {
            title: "Enquiry",
            path: "/enquiry",
          },
        ],
      },
      {
        title: "Cheque",
        path: "/cheque",
        children: [
          {
            title: "Data Editing",
            path: "/data-editing",
          },
          {
            title: "Posting",
            path: "/posting",
          },
          {
            title: "Restore",
            path: "/restore",
          },
          {
            title: "Report",
            path: "/report",
          },
          {
            title: "Enquiry",
            path: "/enquiry",
          },
        ],
      },
      {
        title: "EPS",
        path: "/eps",
      },
    ],
  },
  {
    title: "Account Receivable",
    path: "/account-receivable",
    icon: "form",
    children: [
      {
        title: "Receipting",
        path: "/receipting",
        children: [
          {
            title: "Data Editing",
            path: "/data-editing",
          },
          {
            title: "Posting",
            path: "/posting",
          },
          {
            title: "Restore",
            path: "/restore",
          },
          {
            title: "Report",
            path: "/report",
          },
          {
            title: "Enquiry",
            path: "/enquiry",
          },
        ],
      },
    ],
  },
  {
    title: "Petty Cash",
    path: "/petty-cash",
    icon: "calculator",
    children: [
      {
        title: "Petty Cash Voucher",
        path: "/petty-cash-voucher",
        children: [
          {
            title: "Data Editing",
            path: "/data-editing",
          },
          {
            title: "Posting",
            path: "/posting",
          },
          {
            title: "Restore",
            path: "/restore",
          },
          {
            title: "Report",
            path: "/report",
          },
          {
            title: "Enquiry",
            path: "/enquiry",
          },
        ],
      },
      {
        title: "Cash",
        path: "/cash",
        children: [
          {
            title: "Data Editing",
            path: "/data-editing",
          },
          {
            title: "Report",
            path: "/report",
          },
        ],
      },
    ],
  },
  {
    title: "Bank Recon",
    path: "/bank-recon",
    icon: "bank",
    children: [
      {
        title: "Bank Reconciliation",
        path: "/bank-reconciliation",
        children: [
          {
            title: "Inputting Bank Statement",
            path: "/inputting-bank-statement",
          },
          { title: "Cleared Cheque", path: "/cleared-cheque" },
          { title: "Unpresented Cheque", path: "/unpresented-cheque" },
        ],
      },
      {
        title: "Balance Per Bank Statement",
        path: "/balance-per-bank-statement",
      },
    ],
  },
  {
    title: "Asset",
    path: "/asset",
    icon: "shopping-cart",
    disabled: false,
    children: [
      {
        title: "Registration",
        path: "/registration",
      },
      {
        title: "Listing",
        path: "/listing",
      },
      {
        title: "Report",
        path: "/report",
      },
    ],
  },
  {
    title: "Store",
    path: "/store",
    icon: "database",
    disabled: false,
    children: [
      {
        title: "Items",
        path: "/items",
      },
      {
        title: "Approval",
        path: "/approval",
      },
      {
        title: "Report",
        path: "/report",
      },
    ],
  },
  {
    title: "Reports",
    path: "/reports",
    icon: "line-chart",
  },
  {
    title: "Coding",
    path: "/coding",
    icon: "global",
    children: [
      {
        title: "AG's Rate",
        path: "/ags-rate",
      },
      {
        title: "Creditors",
        path: "/creditors",
      },
      {
        title: "Officers",
        path: "/officers",
      },
      {
        title: "Emails",
        path: "/emails",
      },
      {
        title: "Chart of Accounts",
        path: "/chart-of-accounts",
      },
      {
        title: "Cost Centre",
        path: "/cost-centre",
      },
      {
        title: "Payslip Creation",
        path: "/payslip-creation",
      },
      {
        title: "Overtime Setup",
        path: "/overtime-setup",
      },
      {
        title: "Checklist",
        path: "/checklist",
      },
      {
        title: "Store Item Category",
        path: "/store-item-category",
      },
    ],
  },
  {
    title: "Finance",
    path: "/finance",
    dividerTop: true,
    icon: "solution",
    children: [
      {
        title: "Journal",
        path: "/journal",
        children: [
          {
            title: "Data Editing",
            path: "/data-editing",
          },
          {
            title: "Posting",
            path: "/posting",
          },
          {
            title: "Restore",
            path: "/restore",
          },
          {
            title: "Report",
            path: "/report",
          },
          {
            title: "Enquiry",
            path: "/enquiry",
          },
          {
            title: "Report Setting",
            path: "/report-setting",
          },
        ],
      },
      {
        title: "Query",
        path: "/query",
        children: [
          { title: "Submit Query", path: "/submit-query" },
          { title: "Posting", path: "/posting" },
          { title: "Feedback", path: "/feedback" },
          { title: "Report", path: "/report" },
          { title: "Statistics", path: "/statistics" },
        ],
      },
      { title: "Opening Balance", path: "/opening-balance" },
      {
        title: "Opening Balance Petty Cash",
        path: "/opening-balance-petty-cash",
      },
      { title: "Change OU", path: "/change-ou" },
      // { title: "SPMK Migration", path: "/spmk-migration" },
      {
        title: "Advance Permission",
        path: "/advance-application-permission",
        disabled: false,
      },
      { title: "Posting To Core", path: "/posting-to-core" },
      { title: "KAT Grade", path: "/kat-grade" },
      { title: "Unlock Document Restore", path: "/unlock-document-restore" },
      { title: "Checklist Items", path: "/checklist-items" },
      { title: "Claim Items", path: "/claim-items" },
    ],
  },
  /*  {
    title: "Overall Reports",
    path: "/overall-reports",
    icon: "bar-chart"
  }, */
  {
    title: "Tools",
    path: "/tools",
    icon: "tool",
    children: [
      { title: "Deposit Document Import", path: "/deposit-document-import" },
      { title: "Integration Status", path: "/integration-status" },
      { title: "Integration Scanner", path: "/integration-scanner" },
      { title: "COA Manager", path: "/coa-manager" },
      {
        title: "Special Allocation Report Settings",
        path: "/special-allocation-report-settings",
      },
      {
        title: "Receipt Code Configuration",
        path: "/receipt-code-configuration",
      },
      { title: "EIS Snapshot", path: "/eis-snapshot" },
    ],
  },
  {
    title: "System Admin",
    path: "/system-admin",
    icon: "setting",
    children: [
      {
        title: "User Access",
        path: "/user-access",
        children: [
          {
            title: "Group Setup",
            path: "/group-setup",
          },
          {
            title: "Group Assignment",
            path: "/group-assignment",
          },
          {
            title: "Menu Permission",
            path: "/menu-permission",
          },
        ],
      },
      {
        title: "Maintenance",
        path: "/maintenance",
      },
      {
        title: "Country List",
        path: "/country-list",
      },
      {
        title: "Log",
        path: "/log",
      },
    ],
  },
];

export default menuContent;
