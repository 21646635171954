import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { TOKEN_KEY, TOKEN_EXPIRE_KEY, TOKEN_EXPIRE_MINUTE, AUTO_LOGOUT_IDLE_TIMEOUT } from "../constant";
import moment from "moment";
import Logout from "../functions/LogoutFromServer";
import IdleTimer from "react-idle-timer";

export default function PrivateRoute({ component: Component, ...rest }) {
  let internalProceed = false;
  const tokenExists = localStorage.getItem(TOKEN_KEY) ? true : false;
  const tokenExpire = localStorage.getItem(TOKEN_EXPIRE_KEY);

  const [loginName, setLoginName] = useState("");
  const [loginAsDescription, setLoginAsDescription] = useState("");

  if (tokenExists && tokenExpire) {
    // bandingkan dengan masa semasa

    const past = moment(tokenExpire);
    const now = moment();
    const minutesDiff = now.diff(past, "minutes");

    if (minutesDiff <= TOKEN_EXPIRE_MINUTE) {
      localStorage.setItem(TOKEN_EXPIRE_KEY, new Date().toISOString()); // refresh expire
      internalProceed = true;
    } else {
      Logout();
      localStorage.clear();
    }
  }

  return (
    <IdleTimer
      timeout={1000 * 60 * AUTO_LOGOUT_IDLE_TIMEOUT}
      onIdle={() => {
        Logout();
        localStorage.clear();
        window.location.href = "/";
      }}
    >
      <Route
        {...rest}
        render={(props) => {
          if (internalProceed) {
            return (
              <Component
                {...props}
                loginName={loginName}
                loginAsDescription={loginAsDescription}
                setLoginName={setLoginName}
                setLoginAsDescription={setLoginAsDescription}
              />
            );
          } else {
            return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
          }
        }}
      />
    </IdleTimer>
  );
}
