import axios from "axios";
import { API_URL } from "../constant";

const SetSaveSendEmailResolvedQuery = async () => {
  let res = await axios({
    url: "/set-save-send-email-resolved-query",
    baseURL: API_URL,
    method: "post",
    withCredentials: true,
  });

  if (!res.data.success) {
    throw Error(res.data.message);
  }

  return res.data;
};

export default SetSaveSendEmailResolvedQuery;
