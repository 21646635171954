import axios from "axios";
import { API_URL } from "../constant";

const SetSaveOfficeAccess = async ({ staffId, listOu }) => {
  let res = await axios({
    url: "/set-save-office-access",
    baseURL: API_URL,
    method: "post",
    withCredentials: true,
    data: { staffId, listOu },
  });

  if (!res.data.success) {
    throw Error(res.data.message);
  }

  return res.data;
};

export default SetSaveOfficeAccess;
