import axios from "axios";
import { API_URL } from "../constant";

const Logout = async () => {
  await axios({
    url: "/logout",
    baseURL: API_URL,
    method: "get",
    withCredentials: true,
  });
};

export default Logout;
